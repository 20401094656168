import {bizURL} from "@/config/baseUrl";

export default {
    name: 'login',
    created() {
        this.getCode();
    },

    data() {
        return {
            activeName: 'student',
            codeUrl: "",
            studentForm: {
                username: '',
                password: '',
                uuid: '',
                code: ''
            },
            studentRule: {
                username: [{required: true, message: '用户名为必填', trigger: 'change'}],
                password: [
                    {required: true, message: '密码为必填', trigger: 'change'},
                    {type: 'string', min: 6, message: '密码长度不能小于6位', trigger: 'blur'}
                ],
                code: [{required: true, message: '验证码不能为空', trigger: 'change'}],
            },
            adminForm: {
                username: '',
                password: '',
                uuid: '',
                code:''
            },
            adminRule: {
                username: [
                    {required: true, message: '管理账号为必填', trigger: 'change'}
                ],
                password: [
                    {required: true, message: '密码为必填', trigger: 'change'},
                    {type: 'string', min: 6, message: '密码长度不能小于6位', trigger: 'blur'}
                ],
                code: [{required: true, message: '验证码不能为空', trigger: 'change'}],
            },
            registerModal: false,
            genderList: [],
            uploadUrl: bizURL + "/registration/employee/upload",
            defaultList: [],
            fileData: {},
            registerForm: {
                photo: '',
                name: '',
                gender: '男',
                idNumber: '',
                phoneNumber: '',
                verifyCode: ''
            },
            registerRule: {
                photo: [{required: true, message: '本人证件照为必填', trigger: 'change'}],
                name: [{required: true, message: '姓名为必填', trigger: 'change'}],
                idNumber: [
                    {required: true, message: '身份证号为必填', trigger: 'change'},
                    {pattern: /(^\d{18}$)|(^\d{17}([0-9]|x|X)$)/, message: "证件号码格式有误！", trigger: "blur"}
                ],
                phoneNumber: [
                    {required: true, message: '联系电话为必填', trigger: 'change'},
                    {pattern: /^1\d{10}$/, message: "手机号格式不对", trigger: "blur"}
                ],
                verifyCode: [{required: true, message: '验证码为必填', trigger: 'change'}],
            },
            imgName: '',
            visible: false,
            uploadList: [],
            forgetModal: false,
            forgetForm: {
                name: ''
            },
            forgetRule: {
                name: [
                    {required: true, message: '姓名为必填', trigger: 'blur'}
                ],
            }
        }
    },
    mounted() {
        this.uploadList = this.$refs.photo.fileList;

    },
    methods: {
        // 获取用户信息
        getInfoAndRedirect() {
            this.$manageHttp.getInfo().then(data => {
                if (data.code == '200') {
                    sessionStorage.setItem('userInfo', JSON.stringify(data.user));
                    this.$router.push({
                        path: '/audit'
                    })
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {

            })
        },
        //获取验证码
        getCode() {
            this.$comHttp.getCodeImg().then(res => {
                this.codeUrl = "data:image/gif;base64," + res.img;
                this.studentForm.uuid = res.uuid;
                this.adminForm.uuid = res.uuid;
            });
        },
        // 性别
        biz_gender() {
            this.$comHttp.biz_gender().then(data => {
                if (data.code == '200') {
                    this.genderList = data.data;
                    sessionStorage.setItem('genderList', JSON.stringify(data.data));
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 表单清空
        resetForm(fileName) {
            this.$refs[fileName].resetFields();
        },
        tabChange(name) {
            if (name == 'student') {
                this.$refs.studentForm.resetFields();
            } else {
                this.$refs.adminForm.resetFields();
            }
        },
        instance() {
            const title = '通知！';
            const content = `<p>湖北省2020年度水利电力工程技术中级、高级（副高）职务水平能力测试考试</p><br/><p>报名已于2020年11月12日17:30截止</p><br/>
      <p>请审核通过并缴费人员于2020年11月19日9:00后进入系统打印准考证！</p>`;
            this.$Modal.warning({
                title: title,
                content: content
            });
        },
        // 学员登录
        studentLogin(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$http.login({
                        username: this.studentForm.username,
                        password: this.studentForm.password,
                        uuid:this.studentForm.uuid,
                        code:this.studentForm.code
                    }).then(data => {
                        if (data.code == '200') {
                            var token = `Bearer ${data.token}`;
                            sessionStorage.setItem("token", token);
                            this.$Message.success('登录成功!');
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/home'
                                })
                            }, 1000)
                        } else {
                            this.$Message.error(data.msg);
                            this.getCode();
                        }
                    }).catch(error => {
                        this.getCode();
                    })
                } else {
                    return false;
                }
            })
        },
        // 个人注册
        register() {
            this.registerModal = true;
            this.fileData = {};
            this.biz_gender();
        },
        // 管理部门登录
        adminLogin(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$manageHttp.manageLogin({
                        username: this.adminForm.username,
                        password: this.adminForm.password,
                        uuid:this.adminForm.uuid,
                        code:this.adminForm.code
                    }).then(data => {
                        if (data.code == '200') {
                            var token = `Bearer ${data.token}`;
                            sessionStorage.setItem("manageToken", token);
                            this.$Message.success('登录成功!');
                            setTimeout(() => {
                                this.getInfoAndRedirect();
                            }, 500)
                        } else {
                            this.$Message.error(data.msg);
                            this.getCode();
                        }
                    }).catch(error => {
                        this.getCode();
                    })
                } else {
                    return false;
                }
            })
        },
        // 个人注册
        handleSubmit(name, type) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$http.registration({
                        photo: this.registerForm.photo,
                        name: this.registerForm.name,
                        gender: this.registerForm.gender,
                        idNumber: this.registerForm.idNumber,
                        phoneNumber: this.registerForm.phoneNumber,
                        verifyCode: "123456"
                    }).then(data => {
                        if (data.code == '200' && type == 'success') {
                            this.$Modal.success({
                                title: '恭喜您，注册成功！',
                                content: '<p style="color:red">用户名为身份证号</p></br><p style="color:red">初始密码为身份证后六位</p>',
                                okText: '我知道了',
                                onOk: () => {
                                    this.registerModal = false;
                                }
                            });
                        } else {
                            this.$Message.error(data.msg);
                        }
                    }).catch(error => {

                    })

                } else {
                    return false;
                }
            })
        },
        hasRegister() {

        },
        // handleView(name) {
        //   this.imgName = name;
        //   this.visible = true;
        // },
        // handleRemove(file) {
        //   const fileList = this.$refs.upload.fileList;
        //   this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
        // },
        // handleSuccess(res, file) {
        //   file.url = 'https://o5wwk8baw.qnssl.com/7eb99afb9d5f317c912f08b5212fd69a/avatar';
        //   file.name = '7eb99afb9d5f317c912f08b5212fd69a';
        // },
        // handleMaxSize(file) {
        //   this.$Notice.warning({
        //     title: 'Exceeding file size limit',
        //     desc: 'File  ' + file.name + ' is too large, no more than 2M.'
        //   });
        // },
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                this.$Message.warning({
                    background: true,
                    content: "上传图片大小不能超过 1MB！"
                });
            }
            return isLt2M;
        },
        handleSuccess(res, file) {
            this.fileData = file;
            //file.url = res.url;
            file.url = res.fileName;
            file.name = res.fileName;
            this.registerForm.photo = res.fileName;
            // this.$refs.photo.clearFiles(); // 清空头像
        },
        // 忘记密码
        forgetSubmit(name) {
            // this.$refs[name].validate((valid) => {
            //   if (valid) {
            this.$Message.success('密码修改成功!');
            setTimeout(() => {
                this.forgetModal = false;
            }, 1500)
            //   } else {
            //     return false;
            //   }
            // })
        },

    }
}